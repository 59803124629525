.actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.actions button {
    width: 130px;
    height: 44px;
    background-color: transparent;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}

.actions button:disabled {
    cursor: no-drop;
}

.input-steps-content {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.input > input,
select,
.select{
    width: 100%;
    height: 40px;
    outline: none;
    border: 2px solid;
    font-size: 16px;
    border-radius: 10px;
    padding-left: 5px;
    background-color: transparent;
}
.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #F2F5F7;
    z-index: 1;
    right: 0;
}
.input.display-none {
    display: none;
}
.rmsc{
    --rmsc-border: #F2F5F7;
}
.rmsc .dropdown-heading {
    background-color: #F2F5F7;
    border: 2px solid;
    border-radius: 10px;
}
svg.dropdown-heading-dropdown-arrow.gray {
    display: none;
}
#select-origin {
    appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}