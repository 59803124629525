.container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 40px;
    padding: 80px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-top: 20px;
}

p {
    line-height: 1.6;
}