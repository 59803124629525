*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
.container {
    max-width: 1024px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
a {
    text-decoration: none;
}
.grid {
    display: grid;
}
.tipo{
    display: flex;
    justify-content: space-between;
}
.btn.btn1{
    font-size: small;
}
.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.section h2 {
    font-family: Lato;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.04em;
    text-align: left;

}
.section .section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 83px;
    max-width: 1000px;
}
.section p.text-section {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.04em;
    text-align: center;

}