.footer {
    gap: 70px;
}
.texts-footer {
    max-width: 68%;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    margin-bottom: 98px;
}

.terms{
    outline: none;
    border: none;
    background: none;
    font-size: 18px;
    color: #0000EE;
    cursor: pointer;
}