.content-translation {
    max-width: 89%;
    gap: 32px;
}
.content.content-translation {
    margin-top: 29px;
}

#oquetraduzir {

gap: 30px;


}