.section#quotation-section{
    padding-top: 42px;
    margin-top: 0;


}

.section#quotation-section h1 {
    font-size: 59.83px;
    padding-bottom: 1px;

}

.section #turian {
    font-size: 20.83px;
    margin-left: 200px;
}



