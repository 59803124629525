#logo-navbar {
    max-width: 194px;
    max-height: 54px;
    cursor: pointer;
}
.navbar-content {
    width: 68%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbar-container {
    padding-top: 41px;
    max-width: 100%; 
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}