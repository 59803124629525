.card-testimony {
    max-width: 285px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.text-card-testimony {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.04em;
    text-align: center;
}

.card-testimony .person {
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
}

.card-testimony .person .names {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.card-testimony .person .names p {
    font-size: 20px;
}

.card-testimony .person img {
    width: 84px;
}