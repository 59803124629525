#btn {
    border-radius: 13px;
    width: 335px;
    height: 55px;
    background: #0637A8;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 60px;
    transition: color 0.4s linear;
    position: relative;
}

#btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #c8982a;
    z-index: -1;
    transition: transform 0.5s;
    transform-origin: 0 0;
    transition-timing-function: cubic-bezier(0.5, 1.6, 0.4, 0.7);
    transform: scaleY(0);
}

#btn:hover::before {
    transform: scaleY(1);
}



#close {
    position: absolute;
    right: 20px;
    top: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    background-color: transparent;
}

.button {
    border-radius: 13px;
    width: 335px;
    height: 55px;
    background: #0637A8;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    overflow: hidden;
}

.button button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;

    font-family: Manrope;
    font-size: 20px;
    font-weight: 300;
    line-height: 13px;
    letter-spacing: 1px;


    cursor: pointer;

    position: absolute;
    z-index: 5;
    transition: 0.3s;
    color: #fff;
}
.button:active button {
    transform: scale(0.8);
}

.button::before {
    content: "";
    display: block;
    
    width: 120%;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #C8982A;

    position: absolute;

    transform: scale(0);
    transition: 0.5s;
}
.button:hover::before {
    transform: scale(1);
}

.button::after {
    content: "";
    display: block;
    
    width: 120%;
    aspect-ratio: 1;
    border-radius: 50%;

    position: absolute;

    transform: scale(0);
    transition: 0.3s;
}
.button:active::after {
    transform: scale(1);
}