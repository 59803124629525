.card {
    border: solid 1px #C8982A;
    width: 150px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-card{
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: center;
    align-items: center;
}

.content-card img {
    width: 38px;
}